<template>
  <main class="mr-4">
    <div class="mx-auto md:w-9/12">
      <trac-back-button>Security</trac-back-button>
      <trac-loading v-if="isLoading" />
      <h3 class="my-6 font-bold text-sm">OTP Verification Settings</h3>
    </div>

    <div
      class="shadow-lg bg-white rounded-md p-1 md:w-9/12 mx-auto my-8 flex flex-col items-center"
    >
      <div
        class="grid grid-cols-1 border rounded-md border-gray-400 w-full gap-3"
      >
        <div class="col-span-1 p-2 rounded-lg">
          <div class="flex items-center cursor-pointer">
            <!-- <div class="w-16 bg-blue-100 h-16 rounded-sm flex items-center justify-center">
                <img src="@/assets/svg/otp-icon.svg" alt="tv" />
                </div> -->
            <div class="flex-1 px-4 py-4">
              <h3 class="font-semibold text-sm">Add New Beneficiary</h3>
              <p class="smallest">
                Switch OFF/ON OTP verification when saving or transferring to a
                new beneficiary
              </p>
              <p class="smallest text-blue-700 mt-4">
                <span class="font-bold">Disclaimer:</span> Traction will not be
                responsible for any unauthorized 'WalletToBank' transaction as a
                result of OTP verification being switched OFF.
              </p>
            </div>
            <div
              class="flex-initial h-16 rounded-sm flex items-start justify-center"
            >
              <span :class="{ 'font-bold': !toggle }" class="smallest uppercase"
                >OFF</span
              >
              <div class="pl-3">
                <trac-toggle :active="toggle" @newval="toggleSetting" />
              </div>
              <span
                :class="{ 'font-bold': toggle }"
                class="smallest -ml-1 uppercase"
                >ON</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { eventBus } from "../../eventBus";
export default {
  data() {
    return {
      toggle: true,
      isLoading: false,
      walletDetails: null,
    };
  },
  async created() {
    await this.fetchWalletDetails();
  },
  methods: {
    fetchWalletDetails() {
      this.isLoading = true;
      this.$store.dispatch("FETCH_WALLET_DETAILS").then((res) => {
        if (res.status) {
          this.walletDetails = res.data;
          this.toggle = res.data.hasOtpEnabled;
        } else {
          eventBus.$emit("trac-alert", {
            message: res.message,
          });
        }
        this.isLoading = false
      });
    },
    toggleSetting(value) {
      this.toggle = !this.toggle;
      this.changeSetting();
    },
    changeSetting() {
      const payload = {
        businessId: GET_USER_BUSINESS_ID(),
        status: this.statusValue,
      };
      this.isLoading = true;
      this.$store
        .dispatch("TOGGLE_OTP_REQUIRED_FOR_BENEFICIARY_TRANSFER", payload)
        .then((res) => {
          if (res.status) {
            eventBus.$emit("trac-alert", {
              message: res.message,
            });
            this.isLoading = false;
            this.fetchWalletDetails();
          }
        })
        .catch(() => {
          eventBus.$emit("trac-alert", {
            message:
              "Error occurred while updating OTP settings. Please try again",
          });
          this.isLoading = false;
        });
    },
  },
  computed: {
    statusValue() {
      return this.toggle ? "enable" : "disable";
    },
  },
};
</script>

<style lang="postcss" scoped>
.tiny {
  font-size: 10px;
}
.active-gray {
  background: #e0e0e0;
}
</style>